@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Consulting Pro
Version:        2.0
Created Date:   10.05.2017
Last change:    10.05.2017
Primary use:    Consulting Finance Corporate Business - ConsultingPro
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-konsultpro/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
@import "../css/elegant-icons.css";
/* @import "../css/icomoon.css"; */
/* @import "../css/ionicons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/utility-classes.css";
//@import "../css/flaticon-set-law.css";
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700|Roboto:100,300,400,500,700,900');

// Initialize Variables
@import "less-konsultpro/variables.less";
@import "less-konsultpro/mixins.less";

// Typography
@import "less-konsultpro/typography.less";

// Common Styles
@import "less-konsultpro/common.less";
@import "less-konsultpro/extra.less";
@import "less-konsultpro/overlay.less";

// Header
@import "less-konsultpro/header.less";

// Nav
@import "less-konsultpro/nav.less";

// Content Blocks
@import "less-konsultpro/topbar.less";
@import "less-konsultpro/inner-header-title.less";
@import "less-konsultpro/vertical-nav.less";
@import "less-konsultpro/menu-full-page.less";
@import "less-konsultpro/boxed-layout.less";
@import "less-konsultpro/form.less";
@import "less-konsultpro/side-push-panel.less";
@import "less-konsultpro/box-hover-effect.less";
@import "less-konsultpro/gallery-isotope.less";
@import "less-konsultpro/home.less";
@import "less-konsultpro/contact.less";
@import "less-konsultpro/job.less";
@import "less-konsultpro/event.less";
@import "less-konsultpro/shop.less";
@import "less-konsultpro/blog.less";

// Shortcodes
@import "less-konsultpro/shortcodes.less";


// Widgets
@import "less-konsultpro/widgets.less";



// Widgets
@import "less-konsultpro/custom-theme/loader.less";

// Footer
@import "less-konsultpro/footer.less";