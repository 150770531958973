/*
 * line-bottom.less
 * -----------------------------------------------
*/
.line-bottom {
	position: relative;
	margin-bottom: 30px;
	padding-bottom: 10px;

	&:after {
		bottom: -1px;
		content: "";
		height: 2px;
		left: 0;
		position: absolute;
		width: 50px;
	}
}
.line-bottom-theme-colored2 {
	position: relative;
	margin-bottom: 30px;
	padding-bottom: 10px;

	&:after {
	    border-radius: 10px;
		bottom: -1px;
		content: "";
		height: 2px;
		left: 0;
		position: absolute;
		width: 50px;
	}
}
.line-bottom-rounded-theme-colored2 {
	position: relative;
	margin-bottom: 12px;
	padding-bottom: 4px;

	&:after {
	    border-radius: 15px;
		bottom: -1px;
		content: "";
		height: 4px;
		border-radius: 6px;
		left: 0;
		position: absolute;
		width: 42px;
	}
}
.double-line-bottom-theme-colored2 {
	margin-bottom: 20px;
    margin-top: 8px;
    padding-bottom: 5px;
    position: relative;
	&:after {
		border-radius: 8px;
        bottom: 1px;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 64px;
	}
	&:before {
		border-radius: 8px;
        bottom: -1px;
        content: "";
        height: 6px;
        left: 10px;
        position: absolute;
        width: 24px;
	}
}
.diamond-line-centered-theme-colored2 {
	margin-bottom: 25px;
    margin-top: 25px;
    padding-bottom: 5px;
    position: relative;
	&:after {
	    bottom: 0;
	    content: "";
	    height: 3px;
	    left: 0;
	    margin: 0 auto;
	    position: absolute;
	    right: 0;
	    width: 90px;
	}
	&:before {
	    border: 3px solid #fff;
	    bottom: -5px;
	    content: "";
	    height: 14px;
	    left: 0;
	    margin: 0 auto;
	    position: absolute;
	    right: 0;
	    width: 14px;
	    z-index: 3;
        -webkit-transform: rotate(45deg);
    	-o-transform: rotate(45deg);
    	-ms-transform: rotate(45deg);
        transform: rotate(45deg);
	}
}
.diamond-line-centered-theme-white {
	margin-bottom: 25px;
    margin-top: 25px;
    padding-bottom: 5px;
    position: relative;
	&:after {
	    bottom: 0;
	    content: "";
	    height: 3px;
	    left: 0;
	    margin: 0 auto;
	    position: absolute;
	    right: 0;
	    width: 90px;
	    background-color: #fff;
	}
	&:before {
	    background-color: #fff;
	    border: 3px solid #fff;
	    bottom: -5px;
	    content: "";
	    height: 14px;
	    left: 0;
	    margin: 0 auto;
	    position: absolute;
	    right: 0;
	    width: 14px;
	    z-index: 3;
        -webkit-transform: rotate(45deg);
    	-o-transform: rotate(45deg);
    	-ms-transform: rotate(45deg);
        transform: rotate(45deg);
	}
}
.diamond-line-left-theme-colored2 {
	margin-bottom: 25px;
    margin-top: 25px;
    padding-bottom: 5px;
    position: relative;
	&:after {
	    bottom: 0;
	    content: "";
	    height: 3px;
	    left: 0;
	    position: absolute;
	    right: 0;
	    width: 90px;
	}
	&:before {
	    border: 3px solid #fff;
	    bottom: -5px;
	    content: "";
	    height: 14px;
	    left: 15px;
	    position: absolute;
	    right: 0;
	    width: 14px;
	    z-index: 3;
        -webkit-transform: rotate(45deg);
    	-o-transform: rotate(45deg);
    	-ms-transform: rotate(45deg);
        transform: rotate(45deg);
	}
}
.line-bottom-centered {
    position: relative;
    margin: 10px 0 35px;

	&:after {
        bottom: -13px;
        content: "";
        height: 3px;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        position: absolute;
        right: 0;
        width: 40px;
    }
}
.line-bottom-double-line-centered {
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}
.line-bottom-double-line-centered::before {
  bottom: 3px;
  content: "";
  height: 1px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 20px;
}
.line-bottom-double-line-centered::after {
  bottom: 7px;
  content: "";
  height: 1px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 45px;
}