/*
 * Shortcode: pricing.less
 * -----------------------------------------------
*/
.pricing-table {
	border: 1px solid #ddd;
	margin-bottom: 30px;
	position: relative;
	.table-price {
		color: @black-111;
		font-size: 45px;

		span {
			font-size: 13px;
			vertical-align: middle;
		}
	}

	&.featured {
		border: 1px solid @black-111;
	}

	.table-type {
		display: inline;
	}

	.btn-signup {
		margin-right: 0;
		width: 100%;
	}

	.table-list {
		list-style: outside none none;
		padding: 10px 0 10px 20px;

		li {
			border-bottom: 0;
			font-size: 13px;
			padding: 10px 0;

			i {
			  	margin-right: 8px;
			}
		}
	}

	.package-type {
		span {
			position: relative;
			padding-left: 15px;
			margin-left: 10px;
			
			&::after {
				background: #2d915b;
				content: "";
				height: 20px;
				left: -20px;
				position: absolute;
				top: 11px;
				width: 20px;
			}
		}
	}
}
.pricing-table .price-list li::before {
  left: 18px;
  position: absolute;
  top: 11px;
}
.pricing-table .price-list li {
  border-top: 1px solid #eee;
  padding: 10px 45px;
}
.pricing-table .price-list li:first-child {
  margin-top: 0;
}

.pricing-table.style2 {
	margin-bottom: 30px;
	position: relative;
	.table-price {
		color: @black-111;
		font-size: 45px;

		span {
			font-size: 13px;
			vertical-align: middle;
		}
	}

	&.featured {
		border: 1px solid @black-111;
	}

	.table-type {
		display: inline;
	}

	.btn-signup {
		margin-right: 0;
		width: 100%;
	}

	.table-list {
		list-style: outside none none;
		padding: 10px 0 10px 20px;

		li {
			border-bottom: 0;
			font-size: 14px;
			font-family: 'Open Sans', sans-serif;
			padding: 10px 0;

			i {
			  	margin-right: 8px;
			}
    		&:nth-child(2n+1) {
              background: #f8f8f8 none repeat scroll 0 0;
              border-bottom: 1px solid #eee;
              border-top: 1px solid #eee;
            }
		}
	}

	.package-type {
		span {
			position: relative;
			padding-left: 15px;
			margin-left: 10px;
			
			&::after {
				background: #2d915b;
				content: "";
				height: 20px;
				left: -20px;
				position: absolute;
				top: 11px;
				width: 20px;
			}
		}
	}
}
.package-icon {
  color: #fff;
  font-size: 60px;
  opacity: 0.15;
  position: absolute;
  right: 15px;
  top: -8px;
}
.price .currency {
    vertical-align: super;
}
.d-name {
  display: block;
  line-height: 15px;
}
.pricing-ribbon h5 {
  color: #fff;
  left: -60px;
  line-height: 40px;
  margin: 0;
  position: absolute;
  text-transform: uppercase;
  top: 15px;
  transform: rotate(-45deg);
  width: 190px;
}