/*
 * team.less
 * -----------------------------------------------
*/
.single-member .team-thumb {
  overflow: hidden;
  position: relative;
}
.single-member .team-thumb h4 {
  bottom: 0;
  padding: 10px;
  position: absolute;
  right: -99%;
  transition: all 300ms ease-in-out 0s;
  width: 100%;
}
.single-member:hover .team-thumb h4 {
  right: 0;
}