/*
 * header.less
 * -----------------------------------------------
*/
.navbar-scrolltofixed {
	z-index: 999 !important;
}
//functions
.header-floating-text-color(@color) {
	.header-top,
	.header-mid {
		color: @color;
		.widget a {
			color: @color;
		}
	}
	.header-nav.navbar-scrolltofixed:not(.scroll-to-fixed-fixed) {
		.menuzord-menu > li:not(.active):not(:hover) > a {
			@media only screen and (min-width : 901px) {
				color: @color;
			}
		}
	}
	.sticky-wrapper:not(.is-sticky)  {
		.header-nav.navbar-sticky {
			.menuzord-menu > li:not(.active):not(:hover) > a {
				@media only screen and (min-width : 901px) {
					color: @color;
				}
			}
		}
	}
}

//functions
.header-top-style-bordered(@color) {
	&.style-bordered {
		border-bottom: 1px solid @color;
		.widget {
			ul.list-bordered {
				li {
					padding-left: 1rem;
					padding-right: 1rem;
					border-right: 1px solid @color;
					@media only screen and (max-width : 900px) {
						border: none;
					}
					&:first-child {
						border-left: 1px solid @color;
						@media only screen and (max-width : 900px) {
							border: none;
						}
					}
				}
				&.navbar-nav {
					li {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}

.header-top {
	padding: 0;
	.widget {
		line-height: 3rem;
		margin-bottom: 0;
		ul {
			li {
				margin-bottom: 0;
				padding-bottom: 0;
				&:first-child {
					padding-left: 0;
				}
			}
		}
		.styled-icons {
			line-height: 1;
		}
	}
	.header-top-style-bordered(#ccc);

	.language-switcher {
		@media only screen and (max-width : 900px) {
			background-color: #ddd;
		}
	}
}

@media only screen and (max-width : 767px) {
	.header-top {
	}
}

.menuzord-brand img {
	max-height: 38px;
	@media only screen and (max-width : 767px) {
		max-height: 30px;
	}
}

/* -------- Inner Header ---------- */
.inner-header .title {
	text-transform: uppercase;
}

/* -------- Custom theme style ---------- */
.header-nav .header-nav-wrapper .menuzord {
  padding: 0;
}



/* Transparent Header */
.header {
	/* -------- Header floating ---------- */
	&.header-floating {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: 1111;
		.header-floating-text-color(#fff);

		.header-nav .header-nav-wrapper {
			box-shadow: none;
		}
		.sticky-wrapper.is-sticky  {
			.header-nav.navbar-sticky {
				box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
				.header-nav-wrapper {
					box-shadow: none;
				}
			}
		}
		.header-nav.scroll-to-fixed-fixed .header-nav-wrapper {
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
		}
		&.bottom-box-shadow .header-nav .header-nav-wrapper {
			box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
		}

		/* -------- Header Transparent Dark ---------- */
		&.header-floating-text-dark {
			.header-floating-text-color(#333);
		}

		/* -------- Header Transparent Dark ---------- */
		&.header-floating-text-white {
			.header-floating-text-color(#fff);
		}

		
		/* -------- Header Transparent White ---------- */
		&.header-transparent-white {
			.header-top,
			.header-mid,
			.header-nav {
				background-color: rgba(255, 255, 255, 0.85);
			}
			.header-floating-text-color(#333);
			.header-top {
				.header-top-style-bordered(#ddd);
			}
			.header-nav.scroll-to-fixed-fixed {
				background-color: rgba(255, 255, 255, 1);
			}
		}
		/* -------- Header Transparent Dark ---------- */
		&.header-transparent-dark {
			.header-top,
			.header-mid,
			.header-nav {
				background-color: rgba(0, 0, 0, 0.85);
			}
			.header-floating-text-color(#fff);
			.header-top {
				.header-top-style-bordered(#333);
			}
			.header-nav.scroll-to-fixed-fixed {
				background-color: rgba(22, 22, 22, 1);
			}
			.sticky-wrapper.is-sticky  {
				.header-nav.navbar-sticky {
					background-color: rgba(22, 22, 22, 1);
				}
			}
		}
		@media only screen and (max-width : 900px) {
			position: relative;
			background-color: #333;
			&.header-floating-text-dark {
				background-color: #333;
			}
			&.header-transparent-white {
				background-color: #fff;
			}
		}
	}
	
}


/* -------- Scrolltofixed ---------- */
.header-nav {
	.transition(all 0.4s ease-in-out 0s);
	&.navbar-scrolltofixed {
		&.scroll-to-fixed-fixed {
			background-color: #fff;
		}
	}

	/* -------- Navbar Sticky Animated ---------- */
	&.navbar-sticky-animated .container {
		.transition(all 0.3s ease-in-out 0s);
	}
	&.navbar-sticky-animated:not(.scroll-to-fixed-fixed) .container {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

/* -------- sticky.js ---------- */
&.sticky-wrapper {
	.header-nav.navbar-sticky {
		&.navbar-sticky-animated .container {
			.transition(all 0.3s ease-in-out 0s);
		}
	}
	&.is-sticky {
		.header-nav.navbar-sticky {
			background-color: #fff;
		}
	}
	&:not(.is-sticky) {
		.header-nav.navbar-sticky {
			&.navbar-sticky-animated .container {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
}



/* -------- Switchable Logo ---------- */
.header-nav {
	.switchable-logo {
		.logo-scrolled-to-fixed {
			display: none;
		}
	}
	&.scroll-to-fixed-fixed,
	.scroll-to-fixed-fixed {
		.switchable-logo {
			.logo-default {
				display: none;
			}
			.logo-scrolled-to-fixed {
				display: block;
			}
		}
	}
}
.sticky-wrapper.is-sticky  {
	.header-nav.navbar-sticky {
		.switchable-logo {
			.logo-default {
				display: none;
			}
			.logo-scrolled-to-fixed {
				display: block;
			}
		}
	}
}

/* Modern Header */
.modern-header {
	.header-nav {
		.header-nav-wrapper {
			&:not(.scroll-to-fixed-fixed) {
				box-shadow: none;
				nav {
					background: #222;
				}
			}
			margin-top: -35px;
			position: absolute;
			z-index: 1111;
			left: 0;
			right: 0;
			@media only screen and (max-width : 900px) {
				position: relative;
				margin-top: 0;
				.container {
					padding-left: 0;
					padding-right: 0;
				}
			}
			.menuzord-menu > li {
				> a {
					@media only screen and (min-width : 901px) {
						color: #fff;
					}
				}
			}
			.menuzord-menu > li.home > a {
				padding: 20px 30px;
			}
			&.scroll-to-fixed-fixed {
				margin-top: 0;
				background: #222;
				nav {
					background: transparent;
				}
			}
		}
	}
	&:not(.modern-header-nav-autopadding) {
		.menuzord-menu > li {
			padding: 0;
			> a {
				padding: 25px 30px;
			}
		}
	}
	&.modern-header-white {
		.header-nav {
			.header-nav-wrapper {
				&:not(.scroll-to-fixed-fixed) {
					nav {
						background: #fff;
					}
				}
				.menuzord-menu > li:not(.active):not(:hover) {
					> a {
						@media only screen and (min-width : 901px) {
							color: #222;
						}
					}
				}
				&.scroll-to-fixed-fixed {
					background: #fff;
				}
			}
		}
	}
	.navbar-sticky-animated .container nav {
		.transition(all 0.3s ease-in-out 0s);
	}
	.navbar-sticky-animated:not(.scroll-to-fixed-fixed) .container nav {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.styled-icon-info i {
	background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
	border: 1px solid rgba(255, 255, 255, 0.3);
	color: #ffffff;
	height: 30px;
	line-height: 30px;
	margin-right: 10px;
	text-align: center;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	width: 30px;
}

/* header widget contact info box */
.header-widget-contact-info-box {
	display: flex;
	justify-content: flex-end;
	@media only screen and (max-width : 991px) {
		display: block;
	}
	.element {
		display: inline-block;
		margin-bottom: 15px;
		margin-top: 10px;
		padding-right: 10px;
		margin-left: 20px;
		vertical-align: middle;
		@media only screen and (max-width : 767px) {
			display: block;
		}
	}
	.contact-info {
		float: right;
		@media only screen and (max-width : 1079px) {
			float: none;
		}
		&.media {
			margin-top: 10px;
		}
		.media-body {
			width: auto;
		}
		@media only screen and (max-width : 991px) {
			.media-body, .media-left, .media-right {
				display: block;
				padding-right: 0;
			}
		}

		.font-icon {
			font-size: 30px;
			margin-top: 5px;
		}
		.title {
			color: @gray-base;
			font-size: 12px;
			text-transform: uppercase;
		}
		.subtitle {
			font-size: 12px;
			margin: 0;
		}

		&.small-icon {
			.font-icon {
				font-size: 15px;
				margin-top: 0;
			}
			.icon {
				font-size: inherit;
				font-weight: bold;
				padding-right: 5px;
				margin-top: 0;
			}
			.title {
				color: @black-333;
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
			}
			.subtitle {
				color: @gray-base;
				font-size: 12px;
				font-weight: normal;
				margin: 0;
			}
		}
	}
}