/*
 * nav.less
 * -----------------------------------------------
*/

/* -------- nav sidebar ---------- */
.nav-sidebar {
	li {
		background: @white-f1;
		padding-bottom: 0;

		a {
			border-radius: 0;
		}
	}
}

.nav-pills {
	li {
		a:hover,
		&.active > a,
		&.active > a:hover,
		&.active > a:focus {
			background: @gray-lightgray;
			color: @gray-light;
		}
	}
}

/* -------- Header Nav ---------- */
.header-nav {
	position: relative;
	
	.header-nav-wrapper {
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
	}

	.header-nav-absolute-wrapper {
		width: 100%;
	}
	.header-nav-wrapper .menuzord {
		padding: 0;
	}
}

/* -------- Menuzord ---------- */
.menuzord-menu > li > a {
}
.menuzord-menu > li.home {
	> a {
		> i {
			float: none;
			line-height: 1;
			margin: 0;
		}
	}
}
.menuzord-menu ul.dropdown {
	@media only screen and (min-width : 901px) {
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
	}
}
.menuzord-menu ul.dropdown li {
	margin: 0;
	a { 
		color: @black-666;
		font-size: 13px;
		font-weight: 600;
		padding: 7px 25px 7px 22px;
	}
	.indicator { top: 4px; }
}
.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown {
	min-width: 230px;
}

/* -------- Megamenu-Row ---------- */
.menuzord-menu > li > .megamenu {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
	background-repeat: no-repeat;
	background-position: bottom right;
	&.megamenu-bg-img {
		background-image: url('../images/megamenu/megamenu-dropdown.jpg')
	}
	.megamenu-row {
		ul {
			margin-left: 0;
			li {
				border: 0 none;
				clear: both;
				font-size: 12px;
				margin: 0;
				padding: 2px 0 2px 15px;
				position: relative;
				width: 100%;

				a {
					display: block;
					padding: 8px 0;
					.transition(padding .2s linear);
				}
				&.active > a, &:hover > a {
					color: #111;
					padding-left: 5px;
				}
				a i {
					color: #444;
				}
				&.active a i, .menuzord-menu > li > &:hover a i {
					color: #fff;
				}
			}

			&.list-icon {
				li {
					&:before {
						content: "\f101";
						font-family: fontawesome;
						font-size: 12px;
						left: 0;
						position: absolute;
						top: 10px;
					}
				}
			}
		}
		.megamenu-col-title {
			margin-bottom: 15px;
		}
	}
}

@media only screen and (max-width : 900px) {
	.menuzord-menu {
		background: #fff;
	}
}


.nav-side-icon-list {
	display: inline-block;
	@media only screen and (max-width : 1079px) {
		float: left;
	}
	> li {
		float: left;
		padding: 20px 12px;
		@media only screen and (max-width : 1079px) {
			padding: 13px 12px;
		}

		.custom-button {
			margin-top: -5px;
		}
	}
}


/* Top Nav Search Button & Form */
#top-nav-search-btn {
	.search-icon {
		color: #555;
		font-size: 17px;
		line-height: 1;
		padding: 5px;
		&:hover {
			color: #888;
		}
	}
	@media only screen and (max-width: 1079px) {
		margin-top: 12px;
	}
}
#top-nav-search-form {
	background: #fff;
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 99991;
	form {
		margin-bottom: 0;
	}

	input[type="text"] {
		background: transparent;
		border: medium none;
		border-radius: 0;
		box-shadow: none;
		color: #292929;
		font-size: 24px;
		height: 100%;
		left: 15px;
		line-height: normal;
		outline: medium none;
		padding: 0;
		position: absolute;
		top: 0;
		vertical-align: middle;
		width: 90%;
		@media only screen and (max-width: 1079px) {
			font-size: 18px;
			width: 80%;
		}
	}
	#close-search-btn {
		color: #bbbbbb;
		float: right;
		font-size: 28px;
		line-height: 75px;
		margin: 0 15px 0 0;
		padding: 0;
		&:hover {
			color: #555;
		}
		@media only screen and (max-width: 1079px) {
			line-height: 60px;
		}
	}
}
.modern-header:not(.modern-header-white) {
	#top-nav-search-btn {
		.search-icon {
			color: #fff;
		}
	}
	.top-nav-mini-cart-icon-container {
		.mini-cart-icon {
			i {
				color: #fff;
			}
		}
	}
	.side-panel-trigger {
		i {
			color: #fff;
		}
	}
}