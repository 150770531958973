/*
 * Shortcode: styled-icons.less
 * -----------------------------------------------
*/

.styled-icons {
	a {
		color: @black-333;
		font-size: 18px;
		height: 32px;
		line-height: 32px;
		width: 32px;
		float: left;
		margin: 5px 7px 5px 0;
		text-align: center;
		.transition(all .3s ease-in-out);

		&:hover {
			color: darken(@gray-base, 10%);
		}
	}

	// Icon gray
	&.icon-gray {
		a {
			background-color: @gray-lighter;
			color: @black-555;
			display: block;
			font-size: 18px;
			height: 36px;
			line-height: 36px;
			width: 36px;

			&:hover {
				color: darken(@gray, 10%);
			}
		}

		//icon bordered
		&.icon-bordered {
			a {
				background-color: transparent;
				border: 2px solid @gray-lighter;

				&:hover {
					border: 2px solid darken(@gray-lighter, 10%);
				}
			}

		}
	}

	// Icon dark
	&.icon-dark {
		a {
			background-color: @black-333;
			color: @gray-lighter;
			display: block;
			font-size: 18px;
			height: 36px;
			line-height: 36px;
			width: 36px;

			&:hover {
				color: darken(@gray-light, 10%);
			}
		}

		//icon bordered
		&.icon-bordered {
			a {
				background-color: transparent;
				border: 2px solid @black-111;
				color: @black-111;
				
				&:hover {
					background-color: @black-111;
					border-color: @black-111;
					color: @white-base;
				}
			}

		}
	}

	// Icon bordered
	&.icon-bordered {
		a {
			border: 1px solid @gray-light;

			&:hover {
				background-color: @gray-light;
				color: @white-base;
			}
		}
	}

	// Icon rounded
	&.icon-rounded {
		a {
			border-radius: 3px;
		}		
	}

	// Icon rounded
	&.icon-circled {
		a {
			border-radius: 50%;
		}		
	}

	// Icon sm
	&.icon-sm {
	  a {
	  	font-size: 13px;
		height: 30px;
		line-height: 30px;
		margin: 2px 7px 2px 0;
		width: 30px;
	  }
	}

	// Icon md
	&.icon-md {
	  a {
	  	font-size: 24px;
		height: 50px;
		line-height: 50px;
		width: 50px;
	  }
	}

	// Icon lg
	&.icon-lg {
	  a {
	  	font-size: 32px;
	  	height: 60px;
	  	line-height: 60px;
	  	width: 60px;
	  }
	}

	// Icon xl
	&.icon-xl {
	  a {
	  	font-size: 60px;
	  	height: 120px;
	  	line-height: 120px;
	  	width: 120px;
	  }
	}
}

.styled-icons li {
	display: inline-block;
	margin-bottom: 0;
	margin-top: 0;
}